import React from 'react';
import './style.css';


// import { buscarIcon } from '../../../utils/Compartilhados';
// import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext';
import { useNavigate } from 'react-router-dom'
// import { AuthContext } from '../../Contexts/Auth/AuthContext'
import PortalAdvLogo from '../../Assets/Logins/PortalAdvLogo.png'
import Storage from '../../Utils/Shared/LocalStorage/Storage';
// import ShowModalConfiguracoes from '../ShowModal';


const Header = () => {

    const user = Storage.getLocalStorageUser()
    const navegate = useNavigate()    

    return (      
        <div className='header-main-container'>
            <div className="header-left">
                <div>
                    <img src={PortalAdvLogo} alt="" onClick={()=>navegate('/modulos')}/>
                    {/* <span>{'Paineis de Gestão'}</span> */}
                </div>                
            </div>
            <div className="header-right">
                <div className="header-icon-sino"><img src={''} alt="" /></div>
                <div className="header-perfil">Olá, {user?.nome}</div>
                <div className="header-icon-configuracoes"><img src={''} alt=""  onClick={''}/></div>
            </div>

            {/* {showModalConfiguracoes? <ShowModalConfiguracoes /> : <> </>} */}
        </div>        
    )
}

export default Header;