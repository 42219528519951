
export const useSergurosServices = () => {
    

    const portalSeguros = () => {
        return window.open('https://plataforma.genebraseguros.com.br/Account/Login?whitelabel=legalinsights','_blank')
    };

    return { portalSeguros };
};
