import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import Storage from '../../Utils/Shared/LocalStorage/Storage'

export const PowerBIEmbedContext = createContext({})

function PowerBIEmbedProvider({ children }) {
    const [api] = useState(process.env.REACT_APP_PACT_API)   
    const [loading, setLoading] = useState(false)   
    const [apiGetConfigPortal, setGetConfigPortal] = useState({ CodigoReport: "", Token: "", EmbeddedUrl: ""})   
    const [statusReportsAcesso, setStatusReportsAcesso] = useState(false)
    const [filters, setFilters] = useState([])    
    const [usuarioTemPowerBI, setUsuarioTemPowerBI] = useState(false)
    

    const getPowerBIPortal = async () => { 
        try{
            const user = Storage.getLocalStorageUser()
            user.email = 'caroline.strambeck@pactbr.com'
            setLoading(true)        
            const payload = {
                email: 'caroline.strambeck@pactbr.com'//user.email,
            }
            const _usuarioTemPowerBI = await axios.post(`${api}/powerBI/validaAcesso`, user).then( responseTemPortal => {
                if(responseTemPortal?.status === 200){
                    setUsuarioTemPowerBI(true)
                    return true
                }else{ 
                    setUsuarioTemPowerBI(true)
                    return false
                }                
            })

            if(_usuarioTemPowerBI === true){
                await axios.post(`${api}/powerBI/getConfigPortal`, payload)
                .then( responseGetConfigPortal => {
                    
                    if(responseGetConfigPortal?.status === 200 && responseGetConfigPortal?.data?.length > 0){
                        
                        let payload = {
                            customerId: responseGetConfigPortal.data[0].CustomerId,
                            idPerfil: responseGetConfigPortal.data[0].IdPerfil,
                        }
                        axios.post(`${api}/powerBI/GetPbiEmbed`, payload)
                        .then( responseGetPbiEmbed => {
                            if(responseGetPbiEmbed?.status === 200){
                                responseGetPbiEmbed.data.CodigoPagina = responseGetConfigPortal.data[0].CodigoPagina
                                responseGetPbiEmbed.data.TITULO = responseGetConfigPortal.data[0].TITULO
                                setGetConfigPortal(responseGetPbiEmbed.data)
                                setStatusReportsAcesso(true)
                            }                        
                        })
                        
                        payload.tipo = "G"
                        payload.perfil = 0
                        axios.post(`${api}/powerBI/getPreFiltro`, payload)
                        .then( getPreFiltroG => {
                            if(getPreFiltroG?.status === 200 && getPreFiltroG.data?.data != ''){
                                setFilters((prevFilters)=> [...prevFilters, JSON.parse(getPreFiltroG.data.data)])
                               
                               payload.tipo = "P"                                                    
                               axios.post(`${api}/powerBI/getPreFiltro`, payload)
                               .then( getPreFiltroP => {
           
                                    if(getPreFiltroP?.status === 200  && getPreFiltroP.data.data != ''){
                                        setFilters((prevFilters)=> [...prevFilters, getPreFiltroP.data])
                                        payload.tipo = "U"
                                        payload.userId = responseGetConfigPortal.data[0].UserId
                                        axios.post(`${api}/powerBI/getPreFiltro`, payload)
                                        .then( getPreFiltroU => {
                   
                                            if(getPreFiltroU?.status === 200  && getPreFiltroU.data.data != ''){
                                                setFilters((prevFilters)=> [...prevFilters, getPreFiltroU.data])
                                            }                        
                                       })
                                   }                        
                               })
                            }                        
                        })
                    }else{
                        setStatusReportsAcesso(false)
                    }
                }) 
            }   
            setLoading(false)
        }catch(error){
            console.log("Error: getPowerBIPortal :(||", error)
            setLoading(false)
        }
    }

    return (
        <PowerBIEmbedContext.Provider value={{  
            getPowerBIPortal,
            apiGetConfigPortal, setGetConfigPortal,
            loading, setLoading,
            statusReportsAcesso, setStatusReportsAcesso,
            usuarioTemPowerBI, setUsuarioTemPowerBI
        }}>
            {children}
        </PowerBIEmbedContext.Provider>
    )
}

PowerBIEmbedProvider.propTypes = {
    children: PropTypes.node,
}

export default PowerBIEmbedProvider;