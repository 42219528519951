import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import CustomError from '../../Errors/CustomErros';

export const SecurityContext = createContext({})

function SecurityProvider({ children }) {
    const [load, setLoad] = useState()
    const [stepEtapa, setStepEtapa] = useState(1)

    const baseUrl = process.env.REACT_APP_PACT_API

    const enviarCodigoSeguranca = async (email, event) => {
        try {
            const response = await axios.post(`${baseUrl}/codigoSeguranca/enviar`, { email: email, event: event })
            return response
        } catch (error) {
            throw new CustomError(error.response.data.message, error.response.status)
        }
    }

    const validarCodigoSeguranca = async (email, codigoSeguranca) => {
        try {
            const response = await axios.post(`${baseUrl}/codigoSeguranca/validar`, { email: email, codigoSeguranca: codigoSeguranca })
            return response 
        } catch (error) {
            throw new CustomError(error.response.data.message, error.response.status)
        }
    }

    const validarUsuarioExiste = async (email) => {
        try {              
            const response = await axios.post(`${baseUrl}/usuarios/existe`, {email: email})
            return response            
        } catch (error) {
            throw new CustomError(error.response.data.message, error.response.status)
        }
    }

    const alterarSenha = async (novaSenha, token) => {
        try {
            const response = await axios.post(`${baseUrl}/usuarios/alterarSenha`, { novaSenha: novaSenha }, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response 
        } catch (error) {
            throw new CustomError(error.response.data.message, error.response.status)
        } 
    }
    return (
        <SecurityContext.Provider value={{  
            load, setLoad,
            enviarCodigoSeguranca,
            validarCodigoSeguranca, 
            validarUsuarioExiste,
            alterarSenha,
            stepEtapa, setStepEtapa
        }}>
            {children}
        </SecurityContext.Provider>
    )
}

SecurityProvider.propTypes = {
    children: PropTypes.node,
}

export default SecurityProvider;