
import { Routes, Route } from 'react-router-dom';
import React from 'react'

//Components
import SignIn from '../Pages/SignIn/'
import RequireAuth from './requireAuth';
import Modulos from '../Pages/Modulos';
import EsqueceuSenha from '../Pages/EsqueceuSenha';
import GestaoStatusReport from '../Pages/Modulos/Gestao/StatusReports';

export default function Rotas() {
  return (
    <Routes>
      
      <Route
        path="/"
        element={
          <RequireAuth redirectTo="/modulos" >
            <SignIn />
          </RequireAuth>
        }
      />

      <Route
        path="/modulos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Modulos />
          </RequireAuth>
        }
      />
      
      <Route
        path="/esqueceuSenha"
        element={
          <RequireAuth redirectTo="/" >
            <EsqueceuSenha />
          </RequireAuth>
        }
      />

      <Route
        path="/paineisGestao/statusReports"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <GestaoStatusReport />
          </RequireAuth>
        }
      />
    </Routes>
  )
}