import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Rotas from './Routes'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import { LicenseInfo } from '@mui/x-license-pro';
import AuthProvider from './Contexts/Auth/AuthContext';
import SecurityProvider from './Contexts/Security/SecurityContext';
import PowerBIEmbedProvider from './Contexts/PowerBIEmbed/PowerBIEmbedContext';


function App() {

  // LicenseInfo.setLicenseKey(process.env.REACT_APP_KEY_MATERIAL);

  return (   
    <AuthProvider>    
      <SecurityProvider>
        <PowerBIEmbedProvider>
          <BrowserRouter>
            <ToastContainer autoClose={3000} />
            <Rotas />
          </BrowserRouter>        
        </PowerBIEmbedProvider>
      </SecurityProvider>
    </AuthProvider>
          
  );
}

export default App;
