import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './index.css'
import LogoLegalInsights from '../../Assets/Image/Logo-Legal-Insights-v2.svg'
import { BsFillCircleFill } from 'react-icons/bs'

const CardEtapas = ({ children, titulo, stepEtapa, navegateEtapa, imagem, totalStepEtapas }) => {
    const [cor] = useState('#8BB5E0')
    const [corDefault] = useState('#666666')

    return(<div className="main-container-card-etapas">
        <div className='main-container-left'>
            <div className='left-etapas'>
                <div className='main-container-etapa'>                    
                    <div className='container-etapa-header'>
                        <div className='etapa-header-title'>
                            <span>{titulo}</span>
                            <hr className={`linha-${totalStepEtapas === 3? 'esqueci-a-senha' : 'juridico'}`}/>
                        </div>
                        
                        <div className='etapa-header-steps'>
                            <span> {totalStepEtapas == 0 || totalStepEtapas == undefined? '' :  `Etapa ${stepEtapa} de ${totalStepEtapas}`}</span>
                            
                            {totalStepEtapas === 2 &&
                                <div>
                                    <BsFillCircleFill id='step1' values={1} color={stepEtapa === 1? cor : corDefault} onClick={navegateEtapa}/>
                                    <BsFillCircleFill id='step2' values={2} color={stepEtapa === 2? cor : corDefault} onClick={navegateEtapa}/>
                                </div>
                            }

                            {totalStepEtapas === 3 &&
                                <div>
                                    <BsFillCircleFill id='step1' values={1} color={stepEtapa === 1? cor : corDefault} onClick={navegateEtapa}/>
                                    <BsFillCircleFill id='step2' values={2} color={stepEtapa === 2? cor : corDefault} onClick={navegateEtapa}/>
                                    <BsFillCircleFill id='step3' values={3} color={stepEtapa === 3? cor : corDefault} onClick={navegateEtapa}/>
                                </div>
                            }

                            {totalStepEtapas === 4 &&
                                <div>
                                    <BsFillCircleFill id='step1' values={1} color={stepEtapa === 1? cor : corDefault} onClick={navegateEtapa}/>
                                    <BsFillCircleFill id='step2' values={2} color={stepEtapa === 2? cor : corDefault} onClick={navegateEtapa}/>
                                    <BsFillCircleFill id='step3' values={3} color={stepEtapa === 3? cor : corDefault} onClick={navegateEtapa}/>
                                    <BsFillCircleFill id='step4' values={4} color={stepEtapa === 4? cor : corDefault} onClick={navegateEtapa}/>
                                </div>
                            }
                        </div>                        
                    </div>

                    <div className='container-etapa-conteudo'>
                        {children}
                    </div>                
                </div>
            </div>            
        </div>    
                
        <div className='main-container-right'>
            <div className='right-logo'>
                <img src={LogoLegalInsights} alt="" />
            </div>
            <div className='right-etapas'>
             

                <div className='right-img'>
                    <img src={imagem} alt="" />
                </div>
            </div>            
        </div>            
    </div>)
}

CardEtapas.propTypes = {
    children: PropTypes.node,
    titulo:  PropTypes.string,
    stepEtapa: PropTypes.number,
    navegateEtapa: PropTypes.func,
    imagem: PropTypes.string,
    totalStepEtapas: PropTypes.number
}
export default CardEtapas