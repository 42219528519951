import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import './styled.css'


export const showToast = (type, messagem) => {    
    switch(type){
        case 'success':
            toast.success(messagem,{
                progressClassName: 'custom-toast-progress-success', 
                className: 'custom-toast-success'            
            })
            break;
        
        case 'error':
            toast.error(messagem,{
                progressClassName: 'custom-toast-progress-error', 
                className: 'custom-toast-error'
            })
            break;

        case 'info':
            toast.info(messagem,{
                progressClassName: 'custom-toast-progress-info', 
                className: 'custom-toast-info'
            });
            break;
            
        case 'warning':
            toast.warning(messagem,{
                progressClassName: 'custom-toast-progress-warning', 
                className: 'custom-toast-warning'
            });
            break;
            
        default:
            toast(messagem,{
                progressClassName: 'custom-toast-progress-default', 
                className: 'custom-toast-default'
            });
            break;
    }
}