class Config {
    static REACT_APP_PORTAL_LEGALINSIGHTS_EMAIL = process.env.REACT_APP_PORTAL_LEGALINSIGHTS_EMAIL
    static REACT_APP_PORTAL_LEGALINSIGHTS_PASSWORD = process.env.REACT_APP_PORTAL_LEGALINSIGHTS_PASSWORD
    static REACT_APP_PORTAL_LEGALINSIGHTS_POWERBI_EXTERNO = process.env.REACT_APP_PORTAL_LEGALINSIGHTS_POWERBI_EXTERNO

    values(){
        return[
            Config.REACT_APP_PORTAL_LEGALINSIGHTS_EMAIL,
            Config.REACT_APP_PORTAL_LEGALINSIGHTS_PASSWORD,
            Config.REACT_APP_PORTAL_LEGALINSIGHTS_POWERBI_EXTERNO
        ]
    }

    static isValid(chave) {
        return Config.values().includes(chave);
    }
}

export default Config