class Storage {
    
    getLocalStorageUser(){
        const user = JSON.parse(localStorage.getItem('portal_adv_user'))
        return user
    }

    getLocalStorageToken(){
        const token = JSON.parse(localStorage.getItem('token'))
        return token
    }

    setTokenLocalStorage(token){
        localStorage.setItem('token', token)
    }

    setUserLocalStorage(user){
        localStorage.setItem('portal_adv_user',JSON.stringify(user))
    }

    setCustomLocalStorage(chave, valor){
        localStorage.setItem(chave,JSON.stringify(valor))
    }
}

export default new Storage()