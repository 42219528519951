import React, { useState } from 'react'
import './index.css'
import LoginLeftImage from '../../Assets/Logins/Portal Adv.png'
import PortalLegalLogoImage from '../../Assets/Logins/PortalLegalLogo.ico'
import PortalAdvLogoImage from '../../Assets/Logins/PortalAdvLogo.png'
import { useAuthServices } from '../../Services/Auth/SignInServices'
import { useNavigate } from 'react-router-dom'
import useForm from '../../hooks/useForm'

const SignIn = () => {
  const [form, handleInputChange, clear] = useForm({ email: '', senha: '' })
  const navigate = useNavigate()
  const authServices = useAuthServices()

  const handleAuth = async () => {
    const auth = await authServices.authSignInServices(form.email, form.senha)
    if (auth) {
      navigate('/modulos')
    }
  }

  const handleNavigate = (route) => {
    switch (route) {
      case 'esqueceuSenha':
        navigate('/esqueceuSenha')
        break;
      case 'cadastrar-se':
        navigate('/register')
        break;
      default:
        navigate('/')
        break;
    }
  }

  return (
    <div className='main-login-container'>
      <div className="main-login-header">
        <img src={PortalLegalLogoImage} alt="" className="logo-left" />
        <img src={PortalAdvLogoImage} alt="" className="logo-right" />
      </div>
      <div className="main-login-body">

        <div className="login-left">
          <img src={LoginLeftImage} alt="login-left-imagem" className="login-left-image" />
        </div>

        <div className="login-center">
          <div className="login-center-header">
            <span>Acesso ao Sistema</span>
            <div>
              <hr className='header-line' />
            </div>
          </div>

          <div className="login-center-form">
            <input name='email' type="text" placeholder='E-mail' value={form.email} onChange={handleInputChange} />
            <input name='senha' type="password" placeholder='Senha' value={form.senha} onChange={handleInputChange} />
            <button onClick={handleAuth}>Login</button>
          </div>

          <div className="login-center-footer">
            <a href="#" onClick={(e) => { e.preventDefault(); handleNavigate('esqueceuSenha') }}>Esqueceu a senha?</a>
            {/* <a href="#" onClick={(e) => { e.preventDefault(); handleNavigate('cadastrar-se') }}>Cadastrar-se</a> */}
          </div>
        </div>

        <div className="login-right"></div>
      </div>
      <div className="main-login-footer">
        {/* <img src={IconSair} alt="" onClick={handleSair}/> */}
      </div>

    </div>
  )
}

export default SignIn;
