import React, { useState } from "react"
import PropTypes from 'prop-types'
// import { Button } from "primereact/button"

import './index.css'
import { Input, Button } from '@mui/material'

const btnPosition = {
    LEFT: 'left',
    RIGHT: 'right'
}

const validarInfo = (info) => {
    switch(info){
        case 'info':
            return ' info'
        case 'danger':
            return ' p-button-danger'
        case 'alert':
            return ' p-button-alert'
        case 'success':
            return ' p-button-success mr-2'
        default:
            return ''
    }
}

export const ButtonLabelComponent = ({ label, onClick, position, disabled, index, info, type, icon }) =>{

    return(<Button 
            className={`button-component-${position === 1? btnPosition.LEFT : btnPosition.RIGHT}${validarInfo(info)? validarInfo(info) : '' }${type === 'cancelar'? '-cancelar' : ''}`} 
            label={label} 
            onClick={onClick} 
            disabled={disabled? disabled : false }
            id={index}
            value={index}
            icon={icon? icon : ''}
            name={label}
        />)
}

ButtonLabelComponent.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    position: PropTypes.number,
    disabled: PropTypes.bool,
    index: PropTypes.number,
    info: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.string
}

export const ButtonLabelSearchComponent = ({ name, label, onClick, onchange, value }) => {
    return(
        <Button 
            className={`button-component-search`}             
            placeholder="label"
                  
        >
            <Input name={name} value={value} placeholder={label} onChange={onchange} />
            <img src={''} alt="" className="btn-icon-search"  onClick={onClick}/>
        </Button>
    )
}

ButtonLabelSearchComponent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onchange: PropTypes.func,
    value: PropTypes.string
}

export const ButtonLabel = ({ onClick, children, width }) => {
    const [hover, setHover] = useState(false);

    return(
        <Button 
            className="custom-button-label" 
            name={'button-label'} 
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ 
                border: hover? '2px solid #8621eb' : '1px solid #ac59ff',
                margin: '0 1rem',
                borderRadius: '25px',
                width: width? width : '150px',
                height: '35px',
                color: '#000000',
                
            }}
        >
            {children}
        </Button>        
    )
}

ButtonLabel.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node
}

export const InputTextEmail = ({ onChange, value }) => {
    return(
        <Input className="input-text-email" type="email" name={'email'} value={value} placeholder={'E-Mail'} onChange={onChange} />        
    )
}

InputTextEmail.propTypes = {
    onchange: PropTypes.func,
    value: PropTypes.string
}

export const InputText = ({ onChange, value, label, placeholder }) => {
    return(
        <Input className="input-text" type="text" name={label} value={value} placeholder={placeholder} onChange={onChange} />        
    )
}

InputText.propTypes = {
    onchange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string
}


export const InputTextPassword = ({ onChange, name, value, placeholder }) => {
    console.log('value', value)
    return(
        <Input className="input-text-email" type="password" name={name} onChange={onChange} placeholder={placeholder} />        
    )
}

InputTextPassword.propTypes = {
    onchange: PropTypes.any,
    value: PropTypes.string,
    name: PropTypes.string
}