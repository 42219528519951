import React, { useState, createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import CustomError from '../../Errors/CustomErros';
import Storage from '../../Utils/Shared/LocalStorage/Storage';

export const AuthContext = createContext({})

function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [load, setLoad] = useState()
    const [stepEtapa, setStepEtapa] = useState(1)

    const baseUrl = process.env.REACT_APP_PACT_API

    useEffect(()=>{
        const storageUser = Storage.getLocalStorageUser()
        if(storageUser){
            setUser(storageUser)            
        }
        setLoad(false)
    },[])

    const signInAuth = async (email, senha) => {   
        try {
            
            const payload = {
                email: email,
                senha: senha
            }
            
            const auth = await axios.post(`${baseUrl}/usuarios/auth`,payload)
    
            if(auth.status === 200){
                const token = auth.data.token
                const user = auth.data.user
                Storage.setTokenLocalStorage(token)
                Storage.setUserLocalStorage(user)
                setUser(user)
                return {statusCode: auth.status}
            }        

            return { statusCode: 500}     
        } catch (error) {
            throw new CustomError(error.response.data.message, error.response.status)
        }        
    }

    const signOut = () => {                
        localStorage.clear()
        setUser('')
        return { statusCode: 200 } 
    }

    return (
        <AuthContext.Provider value={{  
            user, setUser,
            signed: !!user,
            load, setLoad,
            signInAuth,
            signOut,
            stepEtapa, setStepEtapa
        }}>
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node,
}

export default AuthProvider;