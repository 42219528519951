import { useContext } from 'react';
import { AuthContext } from '../../Contexts/Auth/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../Utils/Shared/CustomToast';


export const useAuthServices = () => {
    const { signInAuth, signOut, enviarCodigoSeguranca, validarCodigoSeguranca, alterarSenha, validarUsuarioExiste } = useContext(AuthContext);

    const authSignInServices = async (email, senha) => {
        try {

            if(email === '' || email === undefined){
                throw  new Error('E-mail não informado!')
            }

            if(!email.includes('@')){
                throw  new Error('E-mail invalido!')
            }

            if(senha === '' || senha === undefined){
                throw new Error('Senha não informada!')
            }

            const responseAuth = await signInAuth(email, senha);

            if (responseAuth.statusCode === 200) {
                showToast('success', 'Bem vindo de volta!')
                return true;
            }

            if (responseAuth.statusCode !== 200) {
                return false;
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    };

    const authSignOutServices = async () => {
        try {
            const responseAuth = await signOut();

            if (responseAuth.statusCode === 200) {
                showToast('success', 'Desconectado com sucesso!')
                return true;
            }

            if (responseAuth.statusCode !== 200) {
                return false;
            }
        } catch (error) {
            showToast('warning', 'Não foi possível realizar a autenticação')
        }
    };

    const authEnviarCodigoSeguranca = async ( email, metodo ) => {
        try{
            const data = await enviarCodigoSeguranca(email, metodo)
            return data            
        } catch (error) {
            showToast('warning', 'Não foi possível enviar o código de segurança!')
        }
    }

    const authValidarCodigoSeguranca = async ( email, codigoSeguranca ) => {
        try{
            const data = await validarCodigoSeguranca(email, codigoSeguranca)
            return data            
        } catch (error) {
            showToast('warning', 'Não foi possível validar o código de segurança!')
        }
    }

    const authAlterarSenha = async ( email, novaSenha, confirmarNovaSenha ) => {
        try{
            const data = await alterarSenha(email, novaSenha, confirmarNovaSenha)
            return data            
        } catch (error) {
            showToast('warning', 'Não foi possível alterar a senha!')
        }
    }

    const authValidarUsuarioExiste = async ( email ) => {
        try{
            const data = await validarUsuarioExiste(email)
            return data            
        } catch (error) {
            showToast('warning', 'Não foi possível alterar a senha!')
        }
    }


    return { 
        authSignInServices, 
        authSignOutServices, 
        authEnviarCodigoSeguranca,
         authValidarCodigoSeguranca, 
         authAlterarSenha, 
         authValidarUsuarioExiste 
    }
}
