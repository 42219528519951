import { useContext } from "react";
import { PowerBIEmbedContext } from "../../Contexts/PowerBIEmbed/PowerBIEmbedContext";

export const usePowerBIEmbedServices = () => {
    const { getPowerBIPortal, apiGetConfigPortal, setGetConfigPortal, loading, setLoading, statusReportsAcesso, setStatusReportsAcesso, usuarioTemPowerBI, setUsuarioTemPowerBI} = useContext(PowerBIEmbedContext);

    const _getPowerBIPortal = async (email) => {
        getPowerBIPortal(email)
    };

    return { _getPowerBIPortal, apiGetConfigPortal, setGetConfigPortal, loading, setLoading, statusReportsAcesso, setStatusReportsAcesso, usuarioTemPowerBI, setUsuarioTemPowerBI };
};
