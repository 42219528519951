import Config from "../../Utils/Config/Config";

export const usePainelGestaoServices = () => {
    

    const portalPaineisGestao = async (email) => {
        const postData = {
            credenciais: {
                emailmaster: Config.REACT_APP_PORTAL_LEGALINSIGHTS_EMAIL,
                senhamaster: Config.REACT_APP_PORTAL_LEGALINSIGHTS_PASSWORD,
                emailuser: email
            }
        };
          
        const base64Data = btoa(JSON.stringify(postData));
    
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = Config.REACT_APP_PORTAL_LEGALINSIGHTS_POWERBI_EXTERNO;
    
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'data';
        input.value = base64Data;
        form.appendChild(input);
            
        const newWindow = window.open('', '_blank');    
        newWindow.document.title = 'Legal Insights'
        newWindow.document.body.appendChild(form);
        form.submit()
    };

    return { portalPaineisGestao };
};
